import React from 'react';
import Navbar from './components/Navbar';
import Hero from './components/Hero';
import Footer from './components/Footer';
import Divider from './components/Divider';
import VideoShowcase from './components/videoshowcase';



const App = () => {

  const logos  = [
    "/fob1.png",
    "/fob2.png",
    "/fob3.png",
    "/fob1.png",
    "/fob2.png",
    "/fob3.png",
    "/fob1.png",
    "/fob2.png",
    "/fob3.png",
    "/fob1.png",
    "/fob2.png",
    "/fob3.png",
    "/fob1.png",
    "/fob2.png",
    "/fob3.png",
    "/fob1.png",
    "/fob2.png",
    "/fob3.png",
    "/fob1.png",
    "/fob2.png",
    "/fob3.png",
    "/fob1.png",
    "/fob2.png",
    "/fob3.png",
    "/fob1.png",
    "/fob2.png",
    "/fob3.png",

  ]
  
  return (
    <div className="min-h-screen flex flex-col">
      <Navbar />
      <main className="flex-grow">
        <Hero />
        <Divider logos={logos}/>

        <VideoShowcase />
        <Divider logos={logos}/>

      </main>
      <Footer />
    </div>
  );
};

export default App;
