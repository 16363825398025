import React from 'react';

const GifShowcase = () => {
  return (
    <div
      className="min-h-screen p-4"
      style={{
        backgroundImage: `url('/fobbg.jpg')`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      }}
    >
      <div className="flex flex-col md:grid md:grid-cols-2 gap-4">
        <img
          src="https://utfs.io/f/tk0HOGcxeKydXhPMUQS4R6A2fMTd8xilmkhpDOqVPyLEJ9wb"
          alt="GIF 1"
          className="w-full rounded-lg shadow-lg border border-gray-300"
        />
        <img
          src="https://utfs.io/f/tk0HOGcxeKydczGffqOQjqVlUtewNkmTsOX0bfJxdWZrKpMD"
          alt="GIF 2"
          className="w-full rounded-lg shadow-lg border border-gray-300"
        />
        <img
          src="https://utfs.io/f/tk0HOGcxeKydcEm9iDOQjqVlUtewNkmTsOX0bfJxdWZrKpMD"
          alt="GIF 3"
          className="w-full rounded-lg shadow-lg border border-gray-300"
        />
        <img
          src="https://utfs.io/f/tk0HOGcxeKydnv96tZMmC5sZgkul8jUJF0GIbcB3KaXSdvhi"
          alt="GIF 4"
          className="w-full rounded-lg shadow-lg border border-gray-300"
        />
      </div>
    </div>
  );
};

export default GifShowcase;