import React from 'react';

const Navbar = () => (
  <nav className="bg-white shadow-lg p-4 flex justify-between items-center">
    <div className="text-4xl text-gray-800 font-display font-bold">Fob</div>
    <div className="flex space-x-4">
      <a
        href="#home"
        className="text-xl text-gray-800 hover:text-blue-600 font-body transition-colors duration-300"
      >
        Home
      </a>
      <a
        href="#about"
        className="text-xl text-gray-800 hover:text-blue-600 font-body transition-colors duration-300"
      >
        About
      </a>
      <a
        href="#memes"
        className="text-xl text-gray-800 hover:text-blue-600 font-body transition-colors duration-300"
      >
        Memes
      </a>
      <a
        href="#contact"
        className="text-xl text-gray-800 hover:text-blue-600 font-body transition-colors duration-300"
      >
        Contact
      </a>
      <a
        href="https://pump.fun/3adRrkuJmUWb1bCwZDavvewURgZqCnSFhDQPNS8hpump"
        className="bg-gradient-to-r from-blue-500 to-purple-600 text-white text-xl px-4 py-2 rounded-full hover:from-cyan-500 hover:to-purple-700 transition-colors duration-300 font-body"
      >
        Buy Now
      </a>
    </div>
  </nav>
);

export default Navbar;
