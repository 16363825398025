import React, { useState } from 'react';
import { motion } from 'framer-motion';

const Hero = () => {
  const [copied, setCopied] = useState(false);

  const contractAddress = "HRX9BoeaTM9keXiqSAm6HuTzuHRUqTfwixXXBXW4pump";

  const handleCopy = () => {
    navigator.clipboard.writeText(contractAddress);
    setCopied(true);
    setTimeout(() => setCopied(false), 2000);
  };

  return (
    <section
      id="home"
      className="relative bg-white text-gray-800 p-4 sm:p-8 overflow-hidden"
      style={{
        backgroundImage: `url('/fobbg.jpg')`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        width: '100%',
        height: '100vh',
      }}
    >
      {/* Spaceship vaguely in the top-right area */}
      <motion.img
        src="/spaceship.png"
        alt="spaceship"
        className="absolute top-20 right-12 w-64 sm:w-80 md:w-96"
        animate={{
          y: [0, -20, 0],
          x: [0, -10, 10, -10, 0],
        }}
        transition={{
          y: {
            duration: 3,
            repeat: Infinity,
            ease: "easeInOut",
          },
          x: {
            duration: 6,
            repeat: Infinity,
            ease: "easeInOut",
          },
        }}
      />

      <div className="flex justify-center items-center h-full">
        {/* Left Column - Socials */}
        <motion.div
          className="flex flex-col justify-center items-center space-y-2 w-1/3"
          animate={{
            y: [0, -15, 0], // Floating up and down
          }}
          transition={{
            duration: 3,
            repeat: Infinity,
            ease: "easeInOut",
          }}
        >
          <a href="https://dexscreener.com/solana/" target="_blank" rel="noopener noreferrer">
            <img
              src="/buttons/dexscreener.png"
              alt="Dexscreener"
              className="h-12 w-12 sm:h-16 sm:w-16 rounded-full border-4 border-black"
            />
          </a>
          <a href="https://pump.fun/5ojvLF5LiD8Db1ZQR2hMDwh8TX8LJtmzh566G2Fopump" target="_blank" rel="noopener noreferrer">
            <img
              src="/buttons/pumpfun.webp"
              alt="Raydium"
              className="h-12 w-12 sm:h-16 sm:w-16 rounded-full border-4 border-black"
            />
          </a>
          <a href="https://t.me/" target="_blank" rel="noopener noreferrer">
            <img
              src="/buttons/tg.svg"
              alt="Telegram"
              className="h-12 w-12 sm:h-16 sm:w-16 rounded-full border-4 border-black"
            />
          </a>
          <a href="https://x.com/search?q=%24fob&src=typed_query" target="_blank" rel="noopener noreferrer">
            <img
              src="/buttons/tw.svg"
              alt="Twitter"
              className="h-12 w-12 sm:h-16 sm:w-16 rounded-full border-4 border-black"
            />
          </a>
        </motion.div>

        {/* Center Column - Image */}
        <div className="flex flex-col justify-center items-center w-1/3 space-y-4">
          <img
            src="/fob.gif"
            alt="romeo"
            className="w-full sm:w-4/5 max-w-md md:max-w-lg lg:max-w-xl mb-4"
          />
          <div
            className="inline-block px-2 py-1 bg-gray-200 rounded-lg border-4 border-black"
            onClick={handleCopy}
            style={{ cursor: 'pointer', transition: 'background-color 0.3s' }}
          >
            <span className="text-xs sm:text-sm md:text-lg font-bold text-black">
              {copied ? 'Copied!' : 'CA: ' + contractAddress}
            </span>
          </div>
        </div>

        {/* Right Column - Buy Now */}
        <motion.div
          className="flex flex-col justify-center items-center w-1/3"
          animate={{
            y: [0, -10, 0], // Floating up and down
          }}
          transition={{
            duration: 3,
            repeat: Infinity,
            ease: "easeInOut",
          }}
        >
          <a
            href="https://pump.fun"
            target="_blank"
            rel="noopener noreferrer"
            className="relative px-8 py-4 bg-gradient-to-r from-gray-800 to-gray-700 text-gray-300 rounded-full border-4 border-gray-500 text-xl font-bold transform transition-transform hover:scale-105"
            style={{
              boxShadow: 'inset 0 0 15px rgba(255, 255, 255, 0.2), 0 4px 15px rgba(0, 0, 0, 0.5)',
              transform: 'rotate(5deg)',
            }}
          >
            <span className="relative z-10">Buy Now</span>
            {/* Animated glow on hover */}
            <span
              className="absolute inset-0 rounded-full bg-gradient-to-r from-cyan-500 via-blue-600 to-purple-700 opacity-0 transition-opacity hover:opacity-100"
              style={{
                filter: 'blur(10px)',
              }}
            ></span>
          </a>
        </motion.div>
      </div>
    </section>
  );
};

export default Hero;
