import React from 'react';
import PropTypes from 'prop-types';
import { motion } from 'framer-motion';
import './Divider.css';

const Divider = ({ logos }) => {
  // Duplicate logos for smooth looping
  const logosWithDuplicates = [...logos, ...logos];

  return (
    <div className="logos-container">
      <motion.div
        className="logos-slide"
        animate={{ x: ['-100%', '0%'] }} // Move from left to right
        transition={{
          repeat: Infinity,
          repeatType: 'loop',
          duration: 20, // Adjust speed
          ease: 'linear', // Smooth continuous scrolling
        }}
      >
        {logosWithDuplicates.map((logo, index) => (
          <motion.img
            key={index}
            src={logo}
            alt={`Logo ${index + 1}`} // Provide meaningful alt text for accessibility
            className="logo-image"
            animate={{
              rotate: [0, 360], // Full spin animation
            }}
            transition={{
              repeat: Infinity,
              duration: 5, // Adjust speed of spin
              ease: 'linear',
            }}
          />
        ))}
      </motion.div>
    </div>
  );
};

Divider.propTypes = {
  logos: PropTypes.arrayOf(PropTypes.string).isRequired, // Array of logo URLs
};

export default Divider;
